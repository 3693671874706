<template>
  <EndRegistration
    :name="$route.query.company_name"
    :date="$route.query.creation_date"
    title="Informe diligenciado con éxito"
    textBody="El informe diligenciado se ha registrado con éxito y será archivado en el historial  de infomes del Defensor del Consumidor Financiero. El informe se a registrado sobre esta fecha y entidad:"
    :labels="{ labelDate: 'Fecha del informe', labelInput: 'Entidad' }"
    redirectTo=".."
  />
</template>
<script>
import EndRegistration from "@/components/Dashboard/FCO/EndRegistration.vue";
export default {
  name:'endReport',
  components: { EndRegistration },
};
</script>
